import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Layout } from "../components/Layout";
import { Home } from "../pages/Home";
import { NoPageFound } from "../pages/NoPage";
import { AboutUs } from "../pages/AboutUs";
import { ThemeProvider, createTheme } from "@mui/material";
import { ViewProperty } from "../pages/ViewProperty";
import { MyPosts } from "../pages/Myposts";
import { primaryColor } from "../utils/color";
import { Login } from "../pages/Login";
import { Tester } from "../pages/Tester";
import { PostRequests } from "../pages/PostRequests";
import { MyRequests } from "../pages/MyRequests";
import { MySaved } from "../pages/MySaved";
import { ProtectedRoutes } from "../components/ProtectedRoutes";
import { MobileView } from "../components/MobileView";
import { PaymentReceipt } from "../pages/payment-receipt";
import { MobieVerification } from "../pages/mobie-verification";
import { ListProperty } from "../pages/list-property";
import { Disclaimer } from "../pages/disclaimer";
import { PrivacyPolicy } from "../pages/privacy-policy";
import { TermsConditions } from "../pages/terms-conditions";
import { FAQs } from "../pages/faqs";
import { ContactUs } from "../pages/contact-us";
import { Offline } from "../pages/offline";
import { UseIsOffline } from "../hooks/use-is-offline";
import { AccountProfile } from "../pages/account-profile";

const theme = createTheme({
  palette: {
    primary: {
      main: primaryColor[800],
    },
  },
  typography: {
    fontFamily: "'Roboto Serif', serif",
  },
});

export const AppRoutes = () => {
  const { isOnline } = UseIsOffline();

  if (!isOnline) {
    return (
      <>
        <ThemeProvider theme={theme}>
          <Offline />
        </ThemeProvider>
      </>
    );
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <MobileView>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<ProtectedRoutes />}>
                <Route path="list-property" element={<ListProperty />} />
                <Route path="my-saved" element={<MySaved />} />
                <Route path="my-requests" element={<MyRequests />} />
                <Route path="my-posts" element={<MyPosts />} />
                <Route path="post-requests" element={<PostRequests />} />
                <Route path="receipt" element={<PaymentReceipt />} />
                <Route path="verification" element={<MobieVerification />} />
                <Route path="account" element={<AccountProfile />} />
              </Route>
              <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="view-property/:id" element={<ViewProperty />} />
                <Route path="login" element={<Login />} />
                <Route path="about-us" element={<AboutUs />} />
                <Route path="disclaimer" element={<Disclaimer />} />
                <Route path="privacy-policy" element={<PrivacyPolicy />} />
                <Route path="terms-conditions" element={<TermsConditions />} />
                <Route path="faqs" element={<FAQs />} />
                <Route path="contact-us" element={<ContactUs />} />
                <Route path="offline" element={<Offline />} />
                <Route path="tester" element={<Tester />} />
                <Route path="*" element={<NoPageFound />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </MobileView>
      </ThemeProvider>
    </>
  );
};
