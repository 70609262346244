import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
  Link as MuiLink,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { primaryBgColor, primaryColor } from "../utils/color";
import LoginHomeIllustration from "../illustrations/login-home.svg";
import GoogleGIcon from "../icons/googleG.svg";
import AppleIcon from "../icons/apple.svg";
import {
  getAdditionalUserInfo,
  getRedirectResult,
  signInWithRedirect,
} from "firebase/auth";
import {
  appleAuthProvider,
  googleAuth,
  googleAuthProvider,
} from "../firebase/firebaseConfig";
import { LoginTagLine } from "../components/LoginTagLine";
import { useContext, useEffect, useState } from "react";
import { FullScreen } from "../components/FullScreen";
import { ProfileContext } from "../context/ProfileContextProvider";
import { TextLoader } from "../components/text-loader";
import {
  addingDefaultUserData,
  saveMessagingDeviceToken,
} from "../helper/helper";
import { SomethingWentWrongScreen } from "../components/something-went-wrong-screen";
import { isApp } from "../utils/isApp";
import {
  getSignInRedirectPath,
  removeSignInRedirectPath,
} from "../utils/generals";
import { isAndroid, isIOS } from "react-device-detect";
import { isDevMode } from "../utils/is-dev-mode";
import FLAGS from "../flags/flags";

isIOS = isDevMode() && FLAGS.APPLE_SIGN_IN ? true : isIOS;

export const Login = () => {
  const [loginStatus, setLoginStatus] = useState({
    isLoginProcessing: false,
    isLoginError: false,
  });
  const [isTermsConditionsAccepted, setIsTermsConditionsAccepted] =
    useState(true);
  const navigate = useNavigate();
  const { user } = useContext(ProfileContext);
  const { isUserSignedIn, isProfileContextLoading, userData } = user;

  const signInWithGoogle = async () => {
    setLoginStatus({ ...loginStatus, isLoginProcessing: true });

    try {
      await signInWithRedirect(
        googleAuth,
        isIOS ? appleAuthProvider : googleAuthProvider
      );
    } catch (error) {
      handleSignInError(error);
    }
  };

  const handleSignInResult = async (result) => {
    console.log("User logged in successfully - mylogs");
    const { user } = result;

    if (isAndroid && isApp() && Notification.permission === "granted") {
      saveMessagingDeviceToken(user?.uid);
    }

    setLoginStatus({ ...loginStatus, isLoginProcessing: false });
  };

  const handleSignInError = (error) => {
    console.error("Error while logging in - mylogs", error);
    setLoginStatus({ isLoginProcessing: false, isLoginError: true });
  };

  const checkRedirectSignIn = async () => {
    try {
      const result = await getRedirectResult(googleAuth);
      if (result) {
        handleSignInResult(result);

        if (userData?.contactNumber) {
          const redirectPath = getSignInRedirectPath();
          navigate(redirectPath, { replace: true });
          removeSignInRedirectPath();
        } else {
          navigate("/verification", {
            replace: true,
          });
        }

        return;
      }

      if (!isProfileContextLoading && isUserSignedIn) {
        if (userData?.contactNumber) {
          window?.history?.state?.idx > 0 ? navigate(-1) : navigate("/");
        } else {
          navigate("/verification", {
            replace: true,
          });
        }
      }
    } catch (error) {
      handleSignInError(error);
    }
  };

  useEffect(() => {
    // Check for redirect result on component mount
    checkRedirectSignIn();
  }, []);

  if (!isProfileContextLoading && isUserSignedIn) {
    return <FullScreen bgcolor="white"></FullScreen>;
  }

  if (loginStatus.isLoginError) {
    return <SomethingWentWrongScreen />;
  }

  if (loginStatus.isLoginProcessing || isProfileContextLoading) {
    return <TextLoader text="Signing..." />;
  }

  return (
    <>
      <Box sx={{ bgcolor: `${primaryBgColor}`, marginX: "12px" }}>
        <Box
          sx={{
            position: "fixed",
            bottom: "0px",
            top: "0px",
            left: "0px",
            right: "0px",
            bgcolor: "white",
          }}
        >
          <Stack
            justifyContent="center"
            alignItems="center"
            spacing={12}
            sx={{ height: "100%", width: "100%" }}
          >
            <Stack>
              <Stack direction="row" justifyContent="center" spacing={1}>
                <Typography
                  fontSize="34px"
                  fontWeight="medium"
                  color="black"
                  fontFamily="Ultra, serif"
                >
                  Okhla
                </Typography>
                <Typography
                  fontSize="34px"
                  fontWeight="medium"
                  color={primaryColor[900]}
                  fontFamily="Ultra, serif"
                >
                  Homes
                </Typography>
              </Stack>
              <LoginTagLine />
            </Stack>
            <Box
              sx={{
                borderRadius: "100%",
                padding: "12px 5px 5px 5px",
                bgcolor: `${primaryColor[400]}`,
              }}
            >
              <img
                width="290px"
                height="200px"
                alt=""
                src={LoginHomeIllustration}
              />
            </Box>
            <Stack spacing={1.5}>
              <Stack spacing={1} alignItems="center" justifyContent="center">
                <Button
                  sx={{
                    padding: "0px",
                    paddingRight: "10px",
                    paddingLeft: `${isTermsConditionsAccepted ? "4px" : "6px"}`,
                    height: "36px",
                    borderRadius: "6px",
                  }}
                  disabled={!isTermsConditionsAccepted}
                  variant="contained"
                  onClick={signInWithGoogle}
                  startIcon={
                    <Box
                      sx={{
                        borderTopLeftRadius: "6px",
                        borderBottomLeftRadius: "6px",
                        bgcolor: "white",
                        paddingRight: "8px",
                        paddingLeft: "6px",
                        paddingTop: `${
                          isTermsConditionsAccepted ? "6px" : "4px"
                        }`,
                        height: "30px",
                      }}
                    >
                      <img
                        width="25px"
                        height="25px"
                        alt=""
                        src={isIOS ? AppleIcon : GoogleGIcon}
                      />
                    </Box>
                  }
                >
                  Sign In With {isIOS ? "Apple" : "Google"}
                </Button>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isTermsConditionsAccepted}
                      onChange={() =>
                        setIsTermsConditionsAccepted(!isTermsConditionsAccepted)
                      }
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
                    />
                  }
                  label={
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                      <Typography sx={{ fontSize: "14px" }}>
                        I Accept
                      </Typography>
                      <MuiLink
                        sx={{ fontSize: "15px" }}
                        component={Link}
                        to="/terms-conditions"
                      >
                        Terms & Conditions
                      </MuiLink>
                    </Stack>
                  }
                />
              </Stack>
              <Button
                onClick={() => {
                  window?.history?.state?.idx > 0
                    ? navigate(-1)
                    : navigate("/");
                }}
              >
                Skip
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </>
  );
};
