import { Box } from "@mui/material";
import { UseMobileDevice } from "../hooks/use-mobile-device";

export const MainBanner = () => {
  const isMobileDevice = UseMobileDevice();

  return (
    <>
      <Box sx={{ paddingX: "6px" }}>
        <Box>
          <img
            style={{
              width: "100%",
              height: isMobileDevice ? "130px" : "250px",
              aspectRatio: "16/9",
              objectFit: "fill",
              borderRadius: "20px",
            }}
            src={require("../images/banner-1.png")}
            alt=""
          />
        </Box>
      </Box>
    </>
  );
};
