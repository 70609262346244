import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Stack,
  Typography,
} from "@mui/material";
import {
  OAuthProvider,
  deleteUser,
  getRedirectResult,
  revokeAccessToken,
  signInWithRedirect,
  signOut,
} from "firebase/auth";
import { useContext, useEffect, useState } from "react";
import {
  appleAuthProvider,
  googleAuth,
  googleAuthProvider,
} from "../firebase/firebaseConfig";
import { isIOS } from "react-device-detect";
import { PlanHeader } from "../components/plan-header";
import { primaryColor } from "../utils/color";
import { Link, useNavigate } from "react-router-dom";
import { Spacer } from "../components/Spacer";
import {
  Assignment,
  Bookmark,
  Edit,
  Email,
  EmojiPeople,
  Facebook,
  Info,
  Instagram,
  LinkedIn,
  ListAlt,
  LiveHelp,
  Logout,
  NavigateNext,
  PersonOff,
  Phone,
  Quiz,
  Reviews,
  Security,
  Share,
  SupportAgent,
  Warning,
} from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import { ProfileContext } from "../context/ProfileContextProvider";
import { TitleTextActionModal } from "../modals/title-text-action";
import { SignOutSnackbar } from "../components/signout-snackbar";
import { signOutUser } from "../helper/helper";

export const AccountProfile = () => {
  const navigate = useNavigate();
  const { user } = useContext(ProfileContext);
  const { userInfo, userData, isUserSignedIn } = user || {};

  const [isSignOutModalOpen, setIsSignOutModalOpen] = useState({
    isOpen: false,
  });
  const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] = useState({
    isOpen: false,
  });
  const [isSignOutSnackbarOpen, setIsSignOutSnackbarOpen] = useState(false);

  const signIn = async () => {
    try {
      await signInWithRedirect(
        googleAuth,
        isIOS ? appleAuthProvider : googleAuthProvider
      );
    } catch (error) {
      console.error("Error while logging in - mylogs", error);
    }
  };

  const checkRedirectSignIn = async () => {
    try {
      const result = await getRedirectResult(googleAuth);
      if (!result) return;

      if (isIOS) {
        // Handle iOS-specific flow
        const credential = OAuthProvider.credentialFromResult(result);
        if (credential) {
          const accessToken = credential.accessToken;
          if (accessToken) {
            try {
              await revokeAccessToken(googleAuth, accessToken);
              console.log("Access token revoked - mylogs");

              const user = googleAuth.currentUser;
              if (user) {
                await deleteUser(user);
                console.log("User deleted - mylogs");
                navigate("/");
              } else {
                console.error("No user found to delete - mylogs");
              }
            } catch (error) {
              console.error("Error during iOS user cleanup - mylogs", error);
            }
          } else {
            console.error("No access token found - mylogs");
          }
        } else {
          console.error("No credential found - mylogs");
        }
        return;
      }

      // Handle non-iOS flow
      const user = googleAuth.currentUser;
      if (user) {
        try {
          await deleteUser(user);
          console.log("User deleted - mylogs");
          navigate("/");
        } catch (error) {
          console.error("Error deleting user - mylogs", error);
        }
      } else {
        console.error("No user found to delete - mylogs");
      }
    } catch (error) {
      console.error("Error during sign-in redirection - mylogs", error);
    }
  };

  useEffect(() => {
    // Check for redirect result on component mount
    checkRedirectSignIn();
  }, []);

  return (
    <>
      <SignOutSnackbar
        isOpen={isSignOutSnackbarOpen}
        setIsOpen={setIsSignOutSnackbarOpen}
      />
      <TitleTextActionModal
        value="isOpen"
        state={isSignOutModalOpen}
        setState={setIsSignOutModalOpen}
        title="Are you leaving ?"
        text="You can always log back to your account anytime. Do you want to sign out ?"
        actionText="Sign Out"
        actionFunction={() => {
          setIsSignOutModalOpen({
            isOpen: false,
          });
          setIsSignOutSnackbarOpen(true);
          signOutUser(navigate);
        }}
      />
      <TitleTextActionModal
        value="isOpen"
        state={isDeleteAccountModalOpen}
        setState={setIsDeleteAccountModalOpen}
        title="Delete Your Account ?"
        text="Are you sure you want to delete your account? This action is permanent and cannot be undone. All your data will be erased, and you won’t be able to recover it later. Do you wish to proceed ?"
        actionText="Delete"
        actionFunction={() => {
          setIsSignOutModalOpen({
            isOpen: false,
          });
          signIn();
        }}
      />
      <Box>
        <PlanHeader header1="Account" header2="Profile" />
        <Box
          sx={{
            // background: `linear-gradient(180deg, ${primaryColor[800]}, #f5f7fa)`,
            borderBottomLeftRadius: "100%",
            borderBottomRightRadius: "100%",
            backgroundColor: primaryColor[800],
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            width: "100%",
            height: "125px",
            zIndex: "-1",
          }}
          // sx={{
          //   // background: `linear-gradient(180deg, ${primaryColor[800]}, #f5f7fa)`,
          //   backgroundImage: `url(${blobScreen})`,
          //   backgroundRepeat: "no-repeat",
          //   backgroundPosition: "center",
          //   backgroundSize: "cover",
          //   width: "100%",
          //   aspectRatio: "960/300",
          //   // transform: "scaleX(1.5)",
          //   // borderRadius: "0% 0 25% 25%",
          // }}
        ></Box>

        <Stack
          justifyContent="center"
          alignItems="center"
          spacing={1}
          sx={{
            paddingY: "18px",
          }}
        >
          <Avatar
            sx={{
              width: 100,
              height: 100,
              bgcolor: "white",
              color: primaryColor[800],
              border: `1px solid ${grey[300]}`,
            }}
            alt=""
            src={userInfo?.photoURL}
          />
          {isUserSignedIn ? (
            <Stack justifyContent="center" alignItems="center" spacing={-0.5}>
              <Typography
                fontSize="18px"
                fontFamily="Neuton, serif"
                fontWeight="600"
              >
                {userInfo?.displayName}
              </Typography>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Email
                  sx={{
                    fontSize: "17px",
                    paddingTop: "3px",
                    color: `${primaryColor[800]}`,
                  }}
                />
                <Typography fontSize="17px" fontFamily="Neuton, serif">
                  {userInfo?.email}
                </Typography>
              </Stack>
              {userData?.contactNumber ? (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Phone
                    sx={{
                      fontSize: "17px",
                      color: `${primaryColor[800]}`,
                      paddingTop: "3px",
                    }}
                  />
                  <Typography fontSize="17px" fontFamily="Neuton, serif">
                    +91 {userData?.contactNumber}
                  </Typography>
                  <IconButton
                    color="inherit"
                    onClick={() => navigate("/verification")}
                    sx={{ padding: "0px", paddingTop: "3px" }}
                  >
                    <Edit
                      sx={{
                        fontSize: "14px",
                        padding: "1.5px",
                        border: "1px solid black",
                        borderRadius: "30%",
                        color: `${grey[900]}`,
                      }}
                    />
                  </IconButton>
                </Stack>
              ) : (
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <Typography
                    fontSize="17px"
                    fontFamily="Neuton, serif"
                    color={grey[900]}
                  >
                    Add contact number
                  </Typography>
                  <IconButton
                    color="inherit"
                    onClick={() => navigate("/verification")}
                    sx={{ padding: "0px", paddingTop: "3px" }}
                  >
                    <Edit
                      sx={{
                        fontSize: "14px",
                        padding: "1.5px",
                        border: "1px solid black",
                        borderRadius: "30%",
                        color: `${grey[900]}`,
                      }}
                    />
                  </IconButton>
                </Stack>
              )}
            </Stack>
          ) : (
            <Button
              size="large"
              endIcon={
                <NavigateNext
                  sx={{
                    color: "black",
                    paddingTop: "3px",
                  }}
                />
              }
              sx={{
                fontWeight: "600",
                fontSize: "20px",
                fontFamily: "Neuton, serif",
                color: "black",
                textDecoration: "none",
              }}
              component={Link}
              to="/login"
            >
              Login
            </Button>
          )}
        </Stack>

        {/* {isUserSignedIn && <Divider />} */}

        <Box>
          <Spacer space={6} />
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            paddingLeft="16px"
          >
            <Typography
              fontSize="22px"
              fontWeight="bold"
              color="black"
              fontFamily="Neuton, serif"
            >
              Support
            </Typography>
            <Typography
              fontSize="20px"
              fontWeight="bold"
              color={primaryColor[800]}
              fontFamily="Neuton, serif"
            >
              & Information
            </Typography>
          </Stack>
          <Divider variant="middle" sx={{ bgcolor: primaryColor[800] }} />
          <List sx={{ paddingY: "0px" }}>
            <CustomListItem
              navigateTo="/about-us"
              icon={
                <Info sx={{ fontSize: "22px", color: primaryColor[700] }} />
              }
              title="About Us"
            />
            <CustomListItem
              navigateTo="/faqs"
              icon={
                <Quiz sx={{ fontSize: "22px", color: primaryColor[700] }} />
              }
              title="FAQs"
            />
            <CustomListItem
              navigateTo="/contact-us"
              icon={
                <SupportAgent
                  sx={{ fontSize: "22px", color: primaryColor[700] }}
                />
              }
              title="Contact Us"
            />
          </List>
        </Box>
        <Box>
          <Spacer space={6} />
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            paddingLeft="16px"
          >
            <Typography
              fontSize="22px"
              fontWeight="bold"
              color="black"
              fontFamily="Neuton, serif"
            >
              Legal
            </Typography>
            <Typography
              fontSize="20px"
              fontWeight="bold"
              color={primaryColor[800]}
              fontFamily="Neuton, serif"
            >
              & Policies
            </Typography>
          </Stack>
          <Divider variant="middle" sx={{ bgcolor: primaryColor[800] }} />
          <List sx={{ paddingY: "0px" }}>
            <CustomListItem
              navigateTo="/privacy-policy"
              icon={
                <Security sx={{ fontSize: "22px", color: primaryColor[700] }} />
              }
              title="Privacy Policy"
            />
            <CustomListItem
              navigateTo="/terms-conditions"
              icon={
                <Assignment
                  sx={{ fontSize: "22px", color: primaryColor[700] }}
                />
              }
              title="Terms & Conditions"
            />
            <CustomListItem
              navigateTo="/disclaimer"
              icon={
                <Warning sx={{ fontSize: "22px", color: primaryColor[700] }} />
              }
              title="Disclaimers"
            />
          </List>
        </Box>
        {isUserSignedIn && (
          <Box>
            <Spacer space={6} />
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              paddingLeft="16px"
            >
              <Typography
                fontSize="22px"
                fontWeight="bold"
                color="black"
                fontFamily="Neuton, serif"
              >
                Account
              </Typography>
              <Typography
                fontSize="20px"
                fontWeight="bold"
                color={primaryColor[800]}
                fontFamily="Neuton, serif"
              >
                Settings
              </Typography>
            </Stack>
            <Divider variant="middle" sx={{ bgcolor: primaryColor[800] }} />
            <List sx={{ paddingY: "0px" }}>
              <CustomListItem
                onClick={() => {
                  setIsDeleteAccountModalOpen({ isOpen: true });
                }}
                icon={
                  <PersonOff
                    sx={{ fontSize: "22px", color: primaryColor[700] }}
                  />
                }
                title="Delete account"
              />
            </List>
          </Box>
        )}
        <Box>
          <Spacer space={12} />
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            paddingLeft="16px"
          >
            <Typography
              fontSize="22px"
              fontWeight="bold"
              color="black"
              fontFamily="Neuton, serif"
            >
              Social
            </Typography>
            <Typography
              fontSize="20px"
              fontWeight="bold"
              color={primaryColor[800]}
              fontFamily="Neuton, serif"
            >
              Media
            </Typography>
          </Stack>
          <Divider variant="middle" sx={{ bgcolor: primaryColor[800] }} />
          <List sx={{ paddingY: "0px" }}>
            <CustomListItem
              onClick={() => {}}
              icon={
                <Instagram
                  sx={{
                    fontSize: "22px",
                    color: primaryColor[700],
                    paddingTop: "2px",
                  }}
                />
              }
              title="Instagram"
            />
            <CustomListItem
              onClick={() => {}}
              icon={
                <Facebook
                  sx={{
                    fontSize: "22px",
                    color: primaryColor[700],
                    paddingTop: "2px",
                  }}
                />
              }
              title="Facebook"
            />
            <CustomListItem
              onClick={() => {}}
              icon={
                <LinkedIn
                  sx={{
                    fontSize: "22px",
                    color: primaryColor[700],
                    paddingTop: "2px",
                  }}
                />
              }
              title="LinkedIn"
            />
          </List>
        </Box>
        <Box>
          <Spacer space={12} />
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            paddingLeft="16px"
          >
            <Typography
              fontSize="22px"
              fontWeight="bold"
              color="black"
              fontFamily="Neuton, serif"
            >
              App
            </Typography>
            <Typography
              fontSize="20px"
              fontWeight="bold"
              color={primaryColor[800]}
              fontFamily="Neuton, serif"
            >
              Engagement
            </Typography>
          </Stack>
          <Divider variant="middle" sx={{ bgcolor: primaryColor[800] }} />
          <List sx={{ paddingY: "0px" }}>
            <CustomListItem
              navigateTo="/"
              icon={
                <Share sx={{ fontSize: "22px", color: primaryColor[700] }} />
              }
              title="Share App"
            />
            <CustomListItem
              navigateTo="/"
              icon={
                <Reviews sx={{ fontSize: "22px", color: primaryColor[700] }} />
              }
              title="Review Us"
            />
          </List>
        </Box>
        {isUserSignedIn && (
          <Box>
            <Spacer space={24} />
            <Divider variant="middle" sx={{ bgcolor: primaryColor[800] }} />
            <List sx={{ paddingY: "0px" }}>
              <CustomListItem
                onClick={() => {
                  setIsSignOutModalOpen({ isOpen: true });
                }}
                icon={
                  <Logout
                    sx={{
                      fontSize: "22px",
                      color: primaryColor[700],
                      paddingTop: "2px",
                    }}
                  />
                }
                title="Sign Out"
              />
            </List>
          </Box>
        )}
      </Box>
      <Spacer space={70} />
      <Box>
        {/* <Divider />
        <Stack
          direction="row"
          justifyContent="center"
          sx={{ p: "8px" }}
          spacing={0.2}
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontFamily: "Neuton, serif",
            }}
          >
            Developed by
          </Typography>
          <Typography
            fontSize="18px"
            color={primaryColor[800]}
            fontWeight="bold"
            fontStyle="italic"
            fontFamily="Caveat, cursive"
            paddingTop="2px"
          >
            Tlx
          </Typography>
        </Stack> */}
      </Box>
    </>
  );
};

const CustomListItem = ({ navigateTo, onClick, icon, title }) => {
  return (
    <>
      <ListItem
        disablePadding
        component={Link}
        to={navigateTo}
        onClick={onClick}
        sx={{
          textDecoration: "none",
          color: "black",
          fontFamily: "Neuton, serif",
        }}
      >
        <ListItemButton>
          <Stack direction="row" alignItems="flex-end" spacing={1.5}>
            {icon}
            <Typography
              sx={{
                fontFamily: "Neuton, serif",
                fontSize: "20px",
                paddingRight: "10px",
                color: grey[900],
              }}
            >
              {title}
            </Typography>
          </Stack>
        </ListItemButton>
      </ListItem>
    </>
  );
};
