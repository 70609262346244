import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import { primaryColor } from "../utils/color";
import { Spacer } from "./Spacer";
import GooglePlaystoreIcon from "../icons/google-playstore.svg";
import SmileMobileIllustration from "../illustrations/smile-mobile.svg";
import { PLAYSTORE_URL } from "../constants/constants";

export const DesktopViewNotAvailableBanner = () => {
  const styleCheckFlag = useMediaQuery("(max-width:951px)");

  return (
    <>
      <Spacer space={60} />
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ padding: "28px" }}
      >
        <Stack
          sx={{
            width: `${styleCheckFlag ? "95%" : "60%"}`,
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <img
              width={styleCheckFlag ? "260px" : "320px"}
              height={styleCheckFlag ? "260px" : "320px"}
              alt=""
              src={SmileMobileIllustration}
            />
            <img
              style={{
                width: `${styleCheckFlag ? "160px" : "220px"}`,
                height: `${styleCheckFlag ? "80px" : "100px"}`,
                paddingRight: "30px",
              }}
              alt=""
              src={require("../images/custom-okhla-homes-logo.png")}
            />
          </Stack>
          <Spacer space={6} />
          <Box
            sx={{
              border: `3px solid ${primaryColor[900]}`,
              borderRadius: "18px",
              padding: "18px",
            }}
          >
            <Typography>
              Our application is designed specifically for mobile devices to
              provide you with the best experience possible.
            </Typography>
            <Spacer space={20} />
            <Typography sx={{ ...textStyle }}>
              To access Okhla Homes, please visit our website or application on
              your mobile device.
            </Typography>
            <Spacer space={20} />
            <Stack spacing={1}>
              <Typography>
                You can also download our app for a seamless experience:
              </Typography>
              <Box onClick={() => window.open(PLAYSTORE_URL, "_self")}>
                <img height="40px" alt="" src={GooglePlaystoreIcon} />
              </Box>
            </Stack>
            <Spacer space={30} />
            <Typography>
              We appreciate your understanding and look forward to serving you
              on a mobile devices.
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </>
  );
};

const textStyle = {};
