import { createContext, useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { firestoreDb, googleAuth } from "../firebase/firebaseConfig";
import { addDoc, collection, doc, getDoc } from "firebase/firestore";
import * as Sentry from "@sentry/react";
import { hasVisitedToday } from "../utils/generals";
import { addingDefaultUserData } from "../helper/helper";
import { LogoLoader } from "../components/logo-loader";

export const ProfileContext = createContext();

const ProfileContextProvider = ({ children }) => {
  const [user, setUser] = useState({
    isUserSignedIn: false,
    isProfileContextLoading: true,
    isProfileContextError: false,
    hasVisitedToday: hasVisitedToday(),
    userInfo: {},
    userData: {},
  });
  const [triggerProfileContextRes, setTriggerProfileContextRes] =
    useState(false);

  useEffect(() => {
    onAuthStateChanged(googleAuth, async (userRes) => {
      if (userRes) {
        setUser({
          isUserSignedIn: true,
          isProfileContextLoading: true,
          isProfileContextError: false,
          hasVisitedToday: user.hasVisitedToday,
          userInfo: {},
          userData: {},
        });

        const { uid, displayName, email, phoneNumber, photoURL } = userRes;
        Sentry.setUser({ id: uid });

        try {
          const docSnap = await getDoc(doc(firestoreDb, "users", uid));
          if (docSnap.exists()) {
            setUser({
              isUserSignedIn: true,
              isProfileContextLoading: false,
              isProfileContextError: false,
              hasVisitedToday: user.hasVisitedToday,
              userInfo: {
                uid,
                displayName,
                email,
                phoneNumber,
                photoURL,
              },
              userData: docSnap.data(),
            });
          } else {
            console.warn("user default data not found - mylogs");
            await addingDefaultUserData(uid, email);
            setUser({
              isUserSignedIn: true,
              isProfileContextLoading: false,
              isProfileContextError: false,
              hasVisitedToday: user.hasVisitedToday,
              userInfo: {
                uid,
                displayName,
                email,
                phoneNumber,
                photoURL,
              },
              userData: { email, accountStatus: "active" }, // hard coded default user data
            });
          }
        } catch (err) {
          console.error(
            "err while fetching userData - ProfileContextProvider.js - mylogs :",
            err
          );
          setUser({
            isUserSignedIn: true,
            isProfileContextLoading: false,
            isProfileContextError: false,
            hasVisitedToday: user.hasVisitedToday,
            userInfo: {
              uid,
              displayName,
              email,
              phoneNumber,
              photoURL,
            },
            userData: {},
          });
        }
      } else {
        console.warn("User not logged in - mylogs");
        setUser({
          isUserSignedIn: false,
          isProfileContextLoading: false,
          isProfileContextError: false,
          hasVisitedToday: user.hasVisitedToday,
          userInfo: {},
          userData: {},
        });
        Sentry.setUser({ id: "xxx" });
      }
    });
  }, []);

  useEffect(() => {
    if (user?.isUserSignedIn) {
      (async () => {
        try {
          setUser({
            ...user,
            isProfileContextLoading: true,
          });
          const docSnap = await getDoc(
            doc(firestoreDb, "users", user?.userInfo?.uid)
          );

          setUser({
            ...user,
            isProfileContextLoading: false,
            userData: docSnap.exists() ? docSnap.data() : {},
          });
        } catch (err) {
          console.error(
            "err while fetching userData - ProfileContextProvider.js - mylogs :",
            err
          );
          setUser({
            ...user,
            isProfileContextLoading: false,
            userData: {},
          });
        }
      })();
    }
  }, [triggerProfileContextRes]);

  return (
    <>
      {user?.isProfileContextLoading ? (
        <LogoLoader />
      ) : (
        <ProfileContext.Provider
          value={{
            user,
            triggerProfileContextRes,
            setTriggerProfileContextRes,
          }}
        >
          {children}
        </ProfileContext.Provider>
      )}
    </>
  );
};

export default ProfileContextProvider;
